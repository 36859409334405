<template>
    <div class="relative form-control w-full max-w-xs">
        <input
            type="text"
            v-model="selectedOption"
            class="input input-sm input-bordered w-full max-w-xs"
            placeholder="请输入变量, 输入$可查看支持的变量列表"
            @click="toggleDropdown"
        />
        <div v-if="selectedOption[selectedOption.length - 1] === '$'">
            <div class="absolute top-full left-0 z-10 menu p-2 shadow-lg bg-base-100 rounded-box w-full">
                <div
                    v-for="(suggestion, index) in suggestions"
                    :key="index"
                    class="menu-item p-2 cursor-pointer hover:bg-neutral hover:text-white"
                    @click="selectSuggestion(suggestion)"
                >
                    {{ suggestion }}
                </div>
            </div>
        </div>
        <div v-else>
            <div v-if="showDropdown" class="absolute top-full left-0 z-10 menu p-2 shadow-lg bg-base-100 rounded-box w-full">
                <div
                    v-for="(item, index) in options"
                    :key="'op'+index"
                    class="menu-item p-2 cursor-pointer hover:bg-neutral hover:text-white"
                    @click="selectOption(item.name)"
                >
                    {{ item.name }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        value: {
            type: String,
            required: true
        },
        options: {
            type: Array,
            required: true
        }
    },
    data() {
        return {
            showDropdown: false,
            suggestions: ['${artist}', '${album}'],
            selectedOption: this.options.find(opt => opt.id === this.value) ? this.options.find(opt => opt.id === this.value).name : this.value
        };
    },
    computed: {
    },
    methods: {
        toggleDropdown() {
            this.showDropdown = !this.showDropdown;
        },
        selectOption(option) {
            console.log(option)
            this.selectedOption = option;
            this.$emit('input', option);
            this.showDropdown = false;
        },
        selectSuggestion(value) {
            this.selectedOption = this.selectedOption.substring(0, this.selectedOption.length - 1) + value
            this.$emit('input', this.selectedOption);

            this.showDropdown = false;
        }
    }
};
</script>

<style scoped>
.select-box {
    position: relative;
    display: inline-block;
}

.dropdown {
    border: 1px solid #ccc;
    background-color: white;
    position: absolute;
    z-index: 1000;
    max-height: 200px;
    overflow-y: auto;
    width: 100%;
}

.dropdown.active {
    display: block;
}

.dropdown div {
    padding: 5px;
}

.dropdown div:hover {
    background-color: #f8f8f8;
}
</style>
