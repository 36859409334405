import { render, staticRenderFns } from "./fileTree.vue?vue&type=template&id=ca1f4fde&scoped=true"
import script from "./fileTree.vue?vue&type=script&lang=js"
export * from "./fileTree.vue?vue&type=script&lang=js"
import style0 from "./fileTree.vue?vue&type=style&index=0&id=ca1f4fde&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ca1f4fde",
  null
  
)

export default component.exports